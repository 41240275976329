import React, { useState } from "react";

function StudentDetailsCard({ student }: any) {
  return (
    <div className="rounded-lg border my-4 shadow-box_3 overflow-hidden">
      <div className="p-4  h-[65px] border-b flex items-center justify-between">
        <div className="flex items-center space-x-3 ">
          <i className="fa-regular text-[#959595] text-xl fa-user"></i>
          <h3 className="md:text-base  text-[14px] font-semibold  text-[#1E1B59]">
            {student?.name} <br /> {student?.school_generated_id}
          </h3>
        </div>
        <div className="flex flex-col space-y-1 max-w-[11rem]">
          <div className="md:text-base  text-[11px] truncate border rounded-full px-4  border-[#6F6AF880] border-opacity-50 font-normal  text-[#1E1B59]">
            School: {student?.school?.name}
            Puram
          </div>
          <div className="md:text-base  text-[11px] max-w-fit border rounded-full px-4  border-[#6F6AF880] border-opacity-50 font-normal  text-[#1E1B59]">
            Class {student?.class} - {student?.section}
          </div>
        </div>
      </div>
      <div className="p-4 space-x-4 flex text-xs font-medium items-center">
        <div className="space-y-2">
          <p className="text-[#1E1B5999]">Enrollment No.</p>
          <p className="text-[#1E1B5999]">Date of Birth</p>
          <p className="text-[#1E1B5999]">Father’s Name</p>
          <p className="text-[#1E1B5999]">Primary Mobile No.</p>
        </div>
        <div className="space-y-2">
          <p className="text-[#6F6AF8] ">{student?.school_generated_id}</p>
          <p className="text-[#6F6AF8] ">
            {new Date(Number(student?.dob)).toDateString()}
          </p>
          <p className="text-[#6F6AF8] ">{student?.father_name}</p>
          <p className="text-[#6F6AF8] ">
            {student?.phone_number && student?.phone_number[0]}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StudentDetailsCard;
