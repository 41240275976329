import React, { useEffect, useState } from "react";
import "./style.css";
function MultiSelectMenu({
  value,
  options,
  name,
  validator,
  required,
  add_error,
  onChange,
  validate,
  disabled,
  classname,
  isPaid,
  selected: _selected,
}: any) {
  const [error, set_error] = useState("");

  const [selected, set_selected] = React.useState<Array<boolean>>(_selected);

  const [open_menu, set_open_menu] = React.useState(false);
  const [disableItems, setDisableItem] = useState<any>([]);
  // console.log(options.to_pay);

  // React.useEffect(() => {
  //   var selected_template: any = {};
  //   selected_template = {};
  //   options.forEach((option: any) => {
  //     selected_template[option.due_date] = value
  //       ? value.includes(option.due_date)
  //       : false;
  //   });
  //   set_selected(selected_template);
  // }, [value]);

  const _validator = validator
    ? validator
    : required
    ? (s: any) => {
        var a = false;

        for (var c in s) {
          a = a || s[c];
        }
        return !a ? "Atleast one " + name + " is required" : "";
      }
    : (a: any) => "";

  useEffect(() => {}, [options]);
  useEffect(() => {
    if (onChange) onChange(name, selected);
    if (_validator) {
      set_error(_validator(selected));
      add_error(name, _validator(selected));
    } //eslint-disable-next-line
  }, []);

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-start text-gray-900 my-2">
        {name ? "Select " + name : ""}
      </label>
      {/* <div
        onClick={() => set_open_menu(!open_menu)}
        className="relative flex justify-between items-center cursor-pointer max-w-xs w-full rounded-md py-1.5 px-3 text-left text-gray-900 shadow ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6 "
      >
        {select_count ? (
          select_count + " Selected"
        ) : (
          <span>{"Select " + name || ""}</span>
        )}
        <i
          className={
            "fa-solid fa-chevron-up transform transition-all duration-300 " +
            (open_menu ? "  rotate-180" : " -rotate-0")
          }
        ></i>
      </div> */}
      <div className="checkbox-input text-start my-2 text-xs font-light w-full">
        <div
          className={
            "grid grid-cols-1 h-full space-y-[10px] transition-all duration-300 z-10 mt-1 overflow-auto text-base  focus:outline-none sm:text-sm " +
            classname
          }
        >
          <label
            key={-1}
            className="text-sm font-normal text-[#1E1B59] truncate relative  cursor-pointer px-4 pr-9"
          >
            <input
              checked={selected.every((s: any) => s)}
              className="text-[#2EB41880]  my-2 focus:ring-[#2EB41880] text-opacity-50 rounded"
              type="checkbox"
              name=""
              id=""
              onChange={(e) => {
                // var all_selected = selected.every((s: any) => s);
                _selected = options.map((o: any, idx: number) =>
                  disabled[idx] ? selected[idx] : e.target.checked
                );
                set_selected(_selected);
                if (onChange) onChange(e, _selected);
              }}
            />
            <span className="ml-4 text-[14px]">Select All</span>
          </label>
          {options.map((option: any, i: number) => (
            <label
              key={i}
              className="text-gray-900 flex gap-2 items-center truncate rounded-lg relative cursor-pointer py-2 px-2 pr-3"
            >
              <div className="my-auto info-text md:ml-4 ml-2 text-ellipsis grid grid-cols-2 gap-x-3  w-full text-sm font-normal  ">
                <div className="flex gap-x-5 justify-start">
                  <div
                    className={
                      "rounded-full w-[17px] h-[17px] text-[11px] text-[1E1B59] border-[#6F6AF8] border flex justify-center items-center flex-none " +
                      (option.to_pay === 0
                        ? " border-[#6f6af89d] "
                        : " border-[#6F6AF8]")
                    }
                  >
                    {i + 1}
                  </div>
                  <span
                    className={
                      "info-text " +
                      (option.to_pay === 0
                        ? " text-[#1E1B5980] text-opacity-40"
                        : " text-gray-900")
                    }
                  >
                    Installment {i + 1}
                  </span>
                </div>
                <span
                  className={
                    "text-[12px] truncate pl-3 info-text" +
                    (option.to_pay === 0
                      ? " text-[#1E1B5980] text-opacity-40"
                      : " text-[#FC4343E5] text-opacity-90")
                  }
                >
                  Due: {new Date(option.due_date).toDateString()}
                </span>
              </div>
              <input
                className={
                  " text-opacity-50 h-5 w-5 mr-3 rounded " +
                  (option.to_pay === 0
                    ? " text-[#2EB41880] focus:ring-[#2EB41880]"
                    : " text-[#FFD600] focus:ring-[#FFD600]")
                }
                type="checkbox"
                name=""
                id=""
                disabled={
                  (disabled && disabled[i]) || (i > 0 && !selected[i - 1])
                }
                checked={selected[i]}
                onChange={(e: any) => {
                  let _selected = [...selected];
                  _selected[i] = e.target.checked;
                  if (e.target.checked == false) {
                    for (var j = i + 1; j < _selected.length; j++) {
                      _selected[j] = false;
                    }
                  }
                  set_selected(_selected);
                  if (onChange) onChange(e, _selected);
                }}
              />
            </label>
          ))}
        </div>
        <span className="text-red-500 text-[11px]">
          {validate ? error : ""}
        </span>
      </div>
    </>
  );
}

export default MultiSelectMenu;
