/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import StudentSeachBar from "./components/StudentSeachBar";
// import LoadAndRender from "../../components/load-and-render/LoadAndRender";
import { Navigate } from "react-router-dom";
import { GET_INSTALLMENTS } from "../../graphql/queries";
import { useQuery } from "@apollo/client";
import { Mixpanel } from "../../utils/mixpanel";
import decode from "jwt-decode";

// const getFees = async (set_fees) => {
//   console.log("getting fees");
//   var requestOptions: RequestInit = {
//     method: "GET",
//     redirect: "follow",
//   };

//   const fees = await (
//     await fetch(
//       process.env.REACT_APP_BACKEND_URL +
//         "/payment/dues?token=" +
//         localStorage["token"],
//       requestOptions
//     )
//   ).json();
//   set_fees(fees);
// };
function StudentDetails({ set_user }) {
  const { loading, error, data } = useQuery(GET_INSTALLMENTS);
  const user = localStorage.getItem("token");
  if (!user) return <Navigate to="/login" />;
  const phone_number = (decode(user) as Object)["phone_number"];
  if (!phone_number) return <Navigate to="/login" />;
  console.log({ phone_number });
  if (phone_number) Mixpanel.identify(phone_number);
  if (!loading)
    Mixpanel.track("portal_load", {
      phone_number,
    });

  return (
    <div className=" h-screen w-full flex justify-center  items-center py-3 md:px-5 px-0">
      <div className="max-w-[37rem] flex flex-col min-h-full md:p-10 p-5 w-full md:shadow-box md:border rounded-lg relative">
        <div className="absolute top-0 left-0 w-full h-full p-8">
          <h1 className="md:text-[38px] text-[20px] text-[#1E1B59] font-bold">
            Student Details
          </h1>
          <p className="md:text-base text-[14px] text-[#1E1B59] mt-2 font-light">
            Select student you want to pay fees for
          </p>
          <StudentSeachBar data={data?.parent} loading={loading} />
        </div>
        <div className=" fixed h-8 justify-center items-center bottom-0 left-0 rounded-t-lg md:hidden flex w-full bg-[#6F6AF833]">
          <h3 className=" text-xs font-medium">
            <i className="fa-solid fa-shield-halved"></i>
            <span className="text-[#2EB418] ml-2 ">100% Secure</span> Payment
            Gateway
          </h3>
        </div>
      </div>
    </div>
  );
}

export default StudentDetails;
