import React, { useEffect, useState } from "react";
// import payment_susscessfull from "../../assets/payment_success.svg";
import payment_pending from "../../assets/payment_pending.svg";
import { useParams } from "react-router-dom";
import LoadAndRender from "../../components/load-and-render/LoadAndRender";

function PaymentPending() {
  let { id } = useParams();
  const [data, setData] = useState<any>({});
  const intervals = [5000, 10000, 15000, 30000];
  const [intervalIndex, setIntervalIndex] = useState(0);
  const [counter, setCounter] = useState(0);
  const [timer, setTimer] = useState<any>(null);

  useEffect(() => {
    if (intervalIndex < intervals.length) {
      const intervalId = setInterval(() => {
        if (counter < intervals[intervalIndex]) {
          // console.log(
          //   `Interval ${intervalIndex + 1}: ${counter / 1000} seconds`
          // );
          setCounter(counter + 1000);
        } else {
          clearInterval(intervalId);
          setCounter(0);
          setIntervalIndex(intervalIndex + 1);
        }
      }, 1000);

      setTimer(intervalId);
    } else {
      clearInterval(timer);
      console.log("Timer stopped after all intervals");
    }

    return () => {
      clearInterval(timer); // Clear the interval when the component unmounts
    };
  }, [counter]);

  const getInvoice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await (
      await fetch(
        process.env.REACT_APP_BACKEND_URL + `/payment/invoice/${id}`,
        requestOptions
      )
    ).json();
  };

  return (
    <LoadAndRender
      className=" h-full w-full absolute"
      promise={async () => {
        const res = await getInvoice();
        setData(res?.invoice);
      }}
    >
      <div className="flex h-full justify-between  items-center p-4 bg-white">
        <div className="max-w-3xl w-full mx-auto rounded-lg shadow border px-4 py-2">
          <div className="w-full flex justify-center items-center my-4">
            <img src={payment_pending} alt="payment_pending" />
          </div>
          <p className="text-center my-8">
            <span className="text-black text-2xl font-medium ">
              We are confirming your payment. Please wait for a few minutes.
            </span>{" "}
          </p>
          <p className="text-center  text-[#1E1B59] text-md font-medium">
            Order ID: {id}
          </p>
          <p className="text-center mt-4 mb-8 text-[#1E1B59] text-md font-medium">
            Amount: ₹{data?.fee_total && data?.fee_total.toLocaleString("hi")}
          </p>
          <p className="text-xl text-center font-medium text-[#959595] my-4">
            If any money was debited from your account, it will be credited
            back  in 7 business days. Sorry for the inconvenience caused.
          </p>
          <p className="text-xs text-center font-medium text-[#959595] my-4">
            {counter / 1000 > 0
              ? `refeching status in ${counter / 1000} seconds`
              : ""}
          </p>
        </div>
      </div>
    </LoadAndRender>
  );
}

export default PaymentPending;
