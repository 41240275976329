import React from "react";

import { Tooltip as ReactTooltip } from "react-tooltip";
function InstallmentDiscount({ feebreakup, index }: any) {
  const nthNumber = (number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  return (
    <div className="relative mt-4">
      <h2
        data-tooltip-id={index + 1}
        className="text-[16px] font-medium text-[#1E1B59] cursor-pointer"
      >
        {index + 1} <sup>{nthNumber(index + 1)}</sup> Installment
      </h2>

      <ReactTooltip id={index + 1} place="top">
        <div className="space-y-2 text-[12px]">
          <h2>Amount: ₹ {feebreakup.to_pay.toLocaleString("hi")}</h2>
          <h3>Installment Discounts:</h3>
          {feebreakup?.discounts &&
            feebreakup?.discounts?.map((discount: any, i: number) => {
              return (
                <p key={i} className="ml-4">
                  Discount {i + 1}: {discount.value}%
                </p>
              );
            })}
        </div>
      </ReactTooltip>
    </div>
  );
}

export default InstallmentDiscount;
