// import Background from '../assets/background.jpg';

// import "./LogInPage.css"
import Input from "../../components/Input/Input"
import { useState } from "react"
import Button from "../../components/Button/Button"
import { Navigate, useNavigate } from "react-router-dom"
import React from "react"
import phoneIcon from "../../assets/phone.svg"
import optIcon from "../../assets/otp.svg"
import HeroHome from "../../components/Hero/HeroHome"

const get_user = async (token: string) => {
  var myHeaders = new Headers()
  myHeaders.append("Authorization", "Bearer " + token)

  var requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  }
  const { user, school } = await (await fetch(process.env.REACT_APP_BACKEND_URL + "/school-auth/get_user", requestOptions)).json()
  return { ...user, school }
}

async function gen_otp(phone_number: string) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  var raw = JSON.stringify({ phone_number: phone_number })

  var requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  }

  return await fetch(process.env.REACT_APP_BACKEND_URL + "/school-auth/gen_otp", requestOptions)
}

async function verify_otp(otp: string, token: string) {
  var myHeaders = new Headers()
  myHeaders.append("Content-Type", "application/json")

  var raw = JSON.stringify({ token: token, otp: otp + "" })

  var requestOptions: RequestInit = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  }

  return await fetch(process.env.REACT_APP_BACKEND_URL + "/school-auth/verify_otp", requestOptions)
}

const add_error = (name: string, error: string, errors: any, set_errors: any) => {
  set_errors({ ...errors, [name]: error })
}

function LogInPage({ user, set_user }: { user: Map<String, String>; set_user: any }) {
  const [validate, set_validate] = useState(false)
  const [phone_number, set_phone_number] = useState("")
  const [otp, set_otp] = useState("")
  const [otp_sent, set_otp_sent] = useState(false)
  const [errors, set_errors] = useState({} as any)
  const [error, set_error] = useState(null)
  const [token, set_token] = useState("")
  const navigate = useNavigate()

  if (user) return <Navigate to="/"></Navigate>

  return (

    <div className="flex justify-center items-center flex-col w-full h-full">
      <div className="px-4">
        <h2 className="text-[40px] text-[#1E1B59] font-medium">
          Authentication
        </h2>
        <p className="text-lg font-medium text-[#1E1B59]">
          Two-step verification to ensure secure payments{" "}
        </p>
        {!otp_sent ? (
          <Input
            name=""
            prefix={
              <i className="fa-solid fa-phone" style={{ color: "#0a64ff" }}></i>
            }
            // prefix={<img src={phoneIcon} />}
            //prefix={"+91"}
            className={
              " py-3 placeholder:text-[#1E1B59] mt-10 rounded-lg bg-[#DADBFC]"
            }
            placeholder="Enter your registered mobile number"
            maxLength={10}
            onChange={(k: string, v: string) => set_phone_number(v)}
            validator={(res: string) => {
              console.log({ res });
              return res.length === 10
                ? null
                : "Phone Number must be 10 digits long";
            }}
            validate={validate}
            add_error={(name: string, error: string) => {
              add_error(name, error, errors, set_errors);
            }}
            disabled={otp_sent}
            type={""}
          />
        ) : (
          ""
        )}

        {/* Error and Messages */}
        {!otp_sent ? (
          <div className="text-[12px] font-light text-red-500">{error}</div>
        ) : null}
        {otp_sent ? (
          <div className="mt-0">
            <div className="text-violet-500 text-[16px] ">
              Enter <span className="text-green-500">OTP</span> sent to your
              registered mobile number
            </div>
          </div>
        ) : null}
        <div>
          <Button
            name={otp_sent ? "Login" : "Send OTP"}
            className={"bg-[#6F6AF8] rounded-2xl py-3 mt-10 px-4 text-white"}
            onTap={
              otp_sent
                ? async () => {
                    set_validate(true);
                    console.log({ errors });
                    for (var error in errors) {
                      if (errors[error]) {
                        return;
                      }
                    }
                    const res = await verify_otp(otp, token);
                    if (res.ok) {
                      const response = await res.json();
                      localStorage.setItem("token", response.token);
                      set_user(await get_user(response.token));
                      navigate("/");
                      // set_user({response.user});
                    } else {
                      set_error((await res.json()).message);
                    }
                  }
                : async () => {
                    set_validate(true);
                    console.log({ errors });
                    for (var error in errors) {
                      if (errors[error]) {
                        return;
                      }
                    }
                    const res = await gen_otp(phone_number);
                    if (res.ok) {
                      set_otp_sent(true);
                      set_error(null);
                      set_token((await res.json()).token);
                      set_validate(false);
                    } else set_error((await res.json()).message);
                  }
            }
          />
        </div>
      </div>
    </div>
  )
}

export default LogInPage
