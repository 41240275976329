import React, { useEffect, useState } from "react";
import payment_susscessfull from "../../assets/payment_success.svg";
import { Link, useParams } from "react-router-dom";
import LoadAndRender from "../../components/load-and-render/LoadAndRender";
import Modal from "../../components/Modal/Modal";
import DownloadInvoice from "../../components/DownloadInvoice/DownloadInvoice";

import ReactToPrint from "react-to-print";
function PaymentSuccess() {
  let { id } = useParams();
  const [data, setData] = useState<any>({});
  const [open_modal, set_modal] = useState(false);
  const [selected_transaction, set_selected_transaction] = useState<any>(null);
  const [fee_breakup, set_fee_breakup] = useState<any>(null);
  const [fee_heads, set_fee_heads] = useState<any>(null);

  const getInvoice = async () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await (
      await fetch(
        process.env.REACT_APP_BACKEND_URL + `/payment/invoice/${id}`,
        requestOptions
      )
    ).json();
  };

  useEffect(() => {
    if (JSON.parse(fee_breakup)) {
      const res = JSON.parse(fee_breakup)?.installments_to_pay.map(
        (installment: any) => {
          return installment.applicable_fee_heads.map((fee) => {
            return {
              date: fee.due_date,
              name: fee.fee_head.name,
              amount: fee.fee_head.amount,
              to_pay: fee.to_pay,
            };
          });
        }
      );

      set_fee_heads(res);
    }
  }, [open_modal]);

  return (
    <LoadAndRender
      className=" h-full w-full absolute overflow-hidden"
      promise={async () => {
        const res = await getInvoice();
        setData(res?.invoice);
        set_fee_breakup(res?.invoice?.fee_breakup);
      }}
    >
      {data && (
        <div
          className="p-8 min-w-[50rem] w-full absolute top-0"
          id="transaction"
          ref={(e) => {
            set_selected_transaction(e);
          }}
        >
          <div className="flex mb-4 justify-between items-center">
            <h2 className="text-3xl font-normal">Fee Receipt</h2>
            <h2 className="text-2xl font-normal">School Logo</h2>
          </div>
          <hr className="h-1 bg-black" />
          <div className="mt-4 flex justify-between items-center">
            <div className="flex flex-col w-1/2">
              <h3>{data?.school?.name}</h3>
              <div className="mt-2 w-full space-x-4 items-center flex">
                <div className="space-y-4 flex-none">
                  <p className="text-sm text-gray-500 font-light">Address </p>
                  <p className="text-sm text-gray-500 font-light">Phone No. </p>
                  <p className="text-sm text-gray-500 font-light">Email </p>
                </div>
                <div className="space-y-4 ">
                  <p className="text-sm text-gray-500 font-light">:</p>
                  <p className="text-sm text-gray-500 font-light">:</p>
                  <p className="text-sm text-gray-500 font-light">:</p>
                </div>
                <div className="space-y-3  flex-none">
                  <p className="text-md text-black font-bold">
                    {data?.school?.address.street}
                  </p>
                  <p className="text-md text-black font-bold">
                    {data?.school?.phone_number}
                  </p>
                  <p className="text-md text-black font-bold">
                    {data?.school?.email_id}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="w-full space-x-4 justify-end items-center flex">
                <div className="space-y-4 flex-none">
                  <p className="text-sm text-gray-500 font-light">
                    Receipt No.
                  </p>
                  <p className="text-sm text-gray-500 font-light">Date </p>
                </div>
                <div className="space-y-4">
                  <p className="text-sm text-gray-500 font-light">:</p>
                  <p className="text-sm text-gray-500 font-light">:</p>
                </div>
                <div className="space-y-3 flex-none">
                  <p className="text-md text-black font-bold">
                    {data?._id && data?._id.slice(11)}
                  </p>
                  <p className="text-md text-black font-bold">
                    {new Date(data?.createdAt).toDateString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="h-[2px] mt-4 bg-black" />
          <div className="mt-4 flex justify-between items-center">
            <div className="flex flex-col w-1/2">
              <h3>Student Details</h3>
              <div className="mt-2 w-full space-x-4 items-center flex">
                <div className="space-y-4 flex-none">
                  <p className="text-sm text-gray-500 font-light ">
                    Student Name{" "}
                  </p>
                  <p className="text-sm text-gray-500 font-light">
                    Student ID{" "}
                  </p>
                  <p className="text-sm text-gray-500 font-light">
                    Father Name{" "}
                  </p>
                </div>
                <div className="space-y-4">
                  <p className="text-sm text-gray-500 font-light">:</p>
                  <p className="text-sm text-gray-500 font-light">:</p>
                  <p className="text-sm text-gray-500 font-light">:</p>
                </div>
                <div className="space-y-3 h-[6rem] flex-none">
                  <p className="text-md text-black font-bold ">
                    {data?.student?.name}
                  </p>
                  <p className="text-md text-black font-bold">
                    {data?.student?.school_generated_id}
                  </p>
                  <p className="text-md text-black font-bold">
                    {data?.student?.father_name}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="w-full space-x-4 justify-end items-center flex">
                <div className="space-y-4">
                  <p className="text-sm text-gray-500 font-light">Class </p>
                  <p className="text-sm text-gray-500 font-light">
                    Mobile No.{" "}
                  </p>
                </div>
                <div className="space-y-4">
                  <p className="text-sm text-gray-500 font-light">:</p>
                  <p className="text-sm text-gray-500 font-light">:</p>
                </div>
                <div className="space-y-3">
                  <p className="text-md text-black font-bold">
                    {data?.student?.class + "-" + data?.student?.section}
                  </p>
                  <p className="text-md text-black font-bold">
                    {data?.student?.phone_number[0]}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <h1 className="text-center text-2xl">Payment Mode : </h1>
            <div className="w-full rounded-lg border-2 mt-4 border-black">
              <div className="flex w-full">
                <div className="border-r-2 text-center border-b-2 border-black w-full h-10 flex justify-center items-center">
                  Paid Fee Details
                </div>
                <div className="h-10 flex justify-center border-b-2 border-black items-center w-full text-center">
                  Amount
                </div>
              </div>
              <div className="h-auto p-4 flex w-full">
                <ul className="w-full">
                  {JSON.parse(fee_breakup) &&
                    JSON.parse(fee_breakup)?.installments_to_pay.map(
                      (installment: any, i: any) => {
                        return (
                          <div key={i}>
                            {`${i + 1}) `}
                            {new Date(installment.due_date).toDateString()}
                            {installment.applicable_fee_heads.map((fee) => {
                              return (
                                <li className="mt-4" key={fee.fee_head.name}>
                                  <div className="flex w-full justify-between items-center">
                                    <span className="pl-5 w-1/2">
                                      {fee.fee_head.name} :
                                    </span>

                                    <div className="text-center w-1/2">
                                      ₹ {fee.to_pay.toLocaleString("hi")}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </div>
                        );
                      }
                    )}
                </ul>
              </div>
              <div className="flex w-full">
                <div className="border-t-2 border-r-2 text-center border-black w-full h-10 flex justify-center items-center">
                  Total
                </div>
                <div className="h-10 flex justify-center border-t-2 border-black items-center w-full text-center">
                  ₹{data?.fee_total && data?.fee_total.toLocaleString("hi")}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 ">
            <h1 className="text-2xl font-medium">Student Fee Details</h1>
            <div className="w-full space-x-4 mt-4 pl-20 items-center flex">
              <div className="space-y-4">
                <p className="text-md text-black font-bold">Paid </p>
              </div>
              <div className="space-y-4">
                <p className="text-md text-black font-bold">:</p>
              </div>
              <div className="space-y-4">
                <p className="text-md text-black font-bold">
                  ₹{data?.fee_total && data?.fee_total.toLocaleString("hi")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center">
            <p className="border-t-2 max-w-[12rem] text-center w-full border-black">
              <div className="mt-3">Name</div>
            </p>
          </div>
          <p className="text-md mt-4">
            This is a computer generated receipt and does not need a signature.
          </p>
        </div>
      )}

      <div className="flex h-full justify-between w-full z-50 bg-white  items-center p-4">
        <div className="max-w-3xl w-full mx-auto rounded-lg shadow border px-4 py-2">
          <div className="w-full flex justify-center items-center my-4">
            <img src={payment_susscessfull} alt="payment_susscessfull" />
          </div>
          <h3 className="text-black text-3xl font-medium text-center">
            Payment Successful!
          </h3>
          <p className="text-center my-8 text-[#1E1B59] md:text-2xl text-xl font-medium">
            You have successfully paid your child’s fees. The fee receipt has
            also been sent to the registered Whatsapp number.
          </p>
          <p className="text-center  text-[#1E1B59] text-md font-medium">
            Order ID: {id}
          </p>
          <p className="text-center mt-4 mb-8 text-[#1E1B59] text-md font-medium">
            Amount: ₹{data?.fee_total && data?.fee_total.toLocaleString("hi")}
          </p>
          <div className="w-full flex  justify-center items-center">
            <DownloadInvoice
              data={selected_transaction}
              name={data?.student?.name}
              title={"Invoice"}
              fee_breakup={fee_breakup}
            />
          </div>
          <Link to="/fee-details">
            <p className="text-center text-gray-500 my-4">
              go back to home page
            </p>
          </Link>
        </div>
      </div>
    </LoadAndRender>
  );
}

export default PaymentSuccess;
