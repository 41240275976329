import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Modal from "../../../../../components/Modal/Modal";

import BackButton from "../../../../../components/BackButton/BackButton";

import { useMutation } from "@apollo/client";
import { GET_FINAL_AMOUNT, PAY } from "../../../../../graphql/queries";
import Table from "../../../../../components/Table/Table";
import Input from "../../../../../components/Input/Input";

import FeeHead from "../../FeeHead";
import InstallmentDiscount from "../../InstallmentDiscount";
import { Mixpanel } from "../../../../../utils/mixpanel";
import InstallmentCard from "../components/InstallmentCard";
import StudentDetailsCard from "../components/StudentDetailsCard";
import StudentSelectModal from "../../../../../components/StudentSelectModal/StudentSelectModal";
import IndividualFineCard from "../components/IndividualFineCard";
import { preventNegativeValues } from "../../../../../helper/preventNegativeValues";
import { preventPasteNegative } from "../../../../../helper/preventPasteNegative";
import { toast } from "react-toastify";

export const getSum = (total, num) => {
  return total + num;
};

function PaymenProceedNew() {
  const location = useLocation();
  const data = location.state;
  const { selectedInstallments, installments_duedate } = data;

  const [finalAmount, { data: result, loading, error }] =
    useMutation(GET_FINAL_AMOUNT);
  const [PaymentData] = useMutation(PAY);
  const { selected_installments, installments, individual_fines, ...others } =
    selectedInstallments;
  const platform_fee = others?.school?.convenience_fee
    ? others?.school?.convenience_fee
    : 30;
  const [show_status, set_show_status] = useState(false);

  const [open_fee_breakup, set_fee_breakup] = useState(false);
  const [show_discount, set_show_discount] = useState(false);
  const [show_fine, set_show_fine] = useState(false);

  const [payment_status, set_payment_status] = useState("");
  const [finalPayAmount, setFinalPayAmount] = useState<any>(0);
  const [discounts, set_discounts] = useState<any>([]);
  const [fines, set_fines] = useState<any>([]);
  const encRequestRef = React.useRef<HTMLInputElement>(null);
  const access_codeRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const [agree_button, set_agree_button] = useState(false);
  const [showStudentModal, setShowStudentModal] = React.useState(false);
  const [showPreviousDueModal, setShowPreviousDueModal] = React.useState(false);
  const [prev_due_amount, set_prev_due_amount] = useState<any>(0);
  const [prev_due_status, set_prev_due_status] = useState(false);
  const [min_prev_sess_dues_to_pay, set_min_prev_sess_dues_to_pay] =
    useState<any>(0);
  const [max_prev_sess_dues_to_pay, set_max_prev_sess_dues_to_pay] =
    useState<any>(0);
  const [showPreviousDueModalDesktop, setShowPreviousDueModalDesktop] =
    React.useState(false);

  async function paymentInitiate() {
    set_show_status(true);
    set_payment_status("Pending");
    if (
      prev_due_amount < min_prev_sess_dues_to_pay ||
      prev_due_amount > max_prev_sess_dues_to_pay
    ) {
      return toast.error(
        `Previous due amount should be between ${min_prev_sess_dues_to_pay} & ${max_prev_sess_dues_to_pay}`
      );
    }
    const res = await PaymentData({
      variables: {
        pay_till_installment: selectedInstallments.selected_installments.length,
        selected_discounts: [],
        student_id: selectedInstallments._id,
        is_school: false,
        prev_due_amount,
      },
    });
    if (res?.data["pay"]) window.location.replace(res?.data["pay"]);
  }

  const totalAmount = selectedInstallments?.selected_installments.map(
    (feebreakup: any) => {
      return feebreakup.applicable_fee_heads
        .map((feehead: any) => {
          return feehead.to_pay;
        })
        .reduce(getSum, 0);
    }
  );

  useEffect(() => {
    async function amount() {
      const res = await finalAmount({
        variables: {
          pay_till_installment:
            selectedInstallments.selected_installments.length,
          selected_discounts: [],
          student_id: selectedInstallments._id,
          prev_due_amount,
        },
      });
      setFinalPayAmount(res.data.generate_payment_information.amount);
      set_min_prev_sess_dues_to_pay(
        res.data.generate_payment_information.min_prev_sess_dues_to_pay
      );
      if (
        prev_due_amount <
        res.data.generate_payment_information.min_prev_sess_dues_to_pay
      ) {
        set_prev_due_amount(
          res.data.generate_payment_information.min_prev_sess_dues_to_pay
        );
      }
      set_max_prev_sess_dues_to_pay(
        res.data.generate_payment_information.max_prev_sess_dues_to_pay
      );
    }

    const timer = setTimeout(() => {
      amount();
    }, 2000);
    return () => clearTimeout(timer);
  }, [prev_due_amount]);
  const [transaction_expire_time, set_transaction_expire_time] = useState(600);

  useEffect(() => {
    let timer: any;

    if (transaction_expire_time > 0) {
      timer = setInterval(() => {
        set_transaction_expire_time(transaction_expire_time - 1);
      }, 1000);
    }
    if (transaction_expire_time <= 0) {
      window.location.replace("/");
    }

    return () => {
      clearInterval(timer);
    };
  }, [transaction_expire_time]);
  let finalInstallmentCount =
    selectedInstallments?.selected_installments.filter(
      (d: any) => d.to_pay !== 0
    );
  useEffect(() => {
    set_prev_due_amount(min_prev_sess_dues_to_pay);
  }, [min_prev_sess_dues_to_pay]);

  return (
    <>
      <Modal open={show_discount} setOpen={set_show_discount}>
        {discounts !== null && (
          <Table
            heading={"Discounts"}
            data={[
              ["Name", "Value"],
              ...discounts?.map((discount) => [
                discount.name,
                <div>{discount.value}%</div>,
              ]),
            ]}
          />
        )}
        {discounts === null && <div>No Discount Available</div>}
      </Modal>
      <Modal open={show_fine} setOpen={set_show_fine}>
        <div className="grid grid-cols-3"></div>
        {fines !== null && (
          <>
            {selectedInstallments?.individual_fines?.length > 0 && (
              <Table
                heading={"Fines"}
                data={[
                  ["Name", "Amount", "Status"],
                  ...selectedInstallments?.individual_fines?.map((fine) => [
                    fine.name,
                    fine.amount.toLocaleString("hi"),
                    fine.is_paid,
                  ]),
                ]}
              />
            )}
          </>
        )}
        {fines === null && <div>No Fine Available</div>}
      </Modal>
      <Modal
        open={showPreviousDueModalDesktop}
        setOpen={setShowPreviousDueModalDesktop}
        className="max-w-xl w-full"
      >
        <div className="flex flex-col justify-between h-full">
          <div className="p-4 h-full space-y-6">
            <h3 className=" text-xl text-[#1E1B59] font-bold">Previous Dues</h3>
            <Input
              name="Amount"
              type="number"
              value={prev_due_amount}
              onChange={(e: any, value: any) => {
                set_prev_due_amount((prev) => {
                  if (prev <= prev_due_amount) {
                    set_prev_due_status(true);
                  } else {
                    set_prev_due_status(false);
                  }
                });
                set_prev_due_amount(Number(value));
              }}
              min={min_prev_sess_dues_to_pay}
              max={max_prev_sess_dues_to_pay}
              add_error={() => {}}
              onKeyDown={preventNegativeValues}
              onPaste={preventPasteNegative}
              className="border rounded-lg border-[#1E1B59]"
            />
          </div>
          {prev_due_status && !error && (
            <div className="flex justify-center h-16 bg-white items-center shadow-box_3 -mx-6 -mb-4 pt-2 py-3">
              <h3 className="text-[#2EB418E5] text-sm">
                Your dues have been updated ! Please go back to view
              </h3>
            </div>
          )}
        </div>
      </Modal>
      <div className="min-h-screen h-full  bg-white w-full flex justify-center py-3 ">
        <div className="max-w-[37rem] w-full md:shadow-[0px_9px_20px_0px_#00000024]   rounded-lg relative">
          {loading ? (
            <div className="px-4 py-2 flex flex-col justify-between h-full">
              <div className="w-full bg-gray-400 animate-pulse h-96 rounded-lg"></div>
              <div className="space-y-2">
                <div className="w-full bg-gray-400 animate-pulse mt-4 h-5 rounded-lg"></div>
                <div className="w-full bg-gray-400 animate-pulse mt-4 h-10 rounded-lg"></div>
              </div>
            </div>
          ) : (
            <>
              <div className="hidden relative md:flex flex-col justify-between h-full">
                <div className="absolute left-0 w-full h-full overflow-y-scroll scrollbar-hide">
                  <div className="md:h-[62] h-[55px] bg-[#6F6AF8] md:shadow-box shadow-box_1 flex justify-center md:rounded-t-lg md:rounded-b-none  items-center ">
                    <h2 className="text-center md:text-[32px] text-[20px] font-medium text-[#F8FAFB]">
                      {selectedInstallments?.school?.name}
                    </h2>
                  </div>
                  <div className="py-3 w-full pb-3 px-4 flex flex-col h-full justify-between">
                    <div className="h-full">
                      <h3 className=" text-[14px] font-medium text-left text-[#6F6AF8]">
                        You have{" "}
                        <span
                          className={
                            transaction_expire_time <= 59
                              ? "  text-[#FC4343E5]"
                              : "text-[#2EB418E5] "
                          }
                        >
                          {`${Math.floor(transaction_expire_time / 60)} `}
                          {transaction_expire_time -
                            Math.floor(transaction_expire_time / 60) * 60 >
                            0 &&
                            `: ${
                              transaction_expire_time -
                                Math.floor(transaction_expire_time / 60) * 60 <
                              10
                                ? "0"
                                : ""
                            }${
                              transaction_expire_time -
                              Math.floor(transaction_expire_time / 60) * 60
                            } `}
                        </span>
                        mins left to pay {selectedInstallments?.name}’s fees
                      </h3>
                      <h1 className="md:text-[38px] text-[22px] text-[#1E1B59] font-semibold">
                        Student Details
                      </h1>
                      <div className="mt-2 overflow-y-scroll scrollbar-hide space-y-2">
                        <div>
                          <h2 className="text-[#1E1B59] md:text-lg text-base">
                            Full Name
                          </h2>
                          <div className="text-gray-600 text-opacity-75 md:text-lg px-4 py-1 mt-2 border rounded-lg border-[#1E1B59]/20 text-base">
                            {selectedInstallments?.name}
                          </div>
                        </div>
                        <div>
                          <h2 className="text-[#1E1B59] md:text-lg text-base">
                            Enrolment Number
                          </h2>
                          <div className="text-gray-600 text-opacity-75 md:text-lg px-4 py-1 mt-2 border rounded-lg border-[#1E1B59]/20 text-base w-full">
                            {selectedInstallments?.school_generated_id}
                          </div>
                        </div>
                        <div>
                          <h2 className="text-[#1E1B59] md:text-lg text-base">
                            Date of Birth
                          </h2>

                          <div className="text-gray-600 text-opacity-75 md:text-lg px-4 py-1 mt-2 border rounded-lg border-[#1E1B59]/20 text-base w-full">
                            {new Date(
                              Number(selectedInstallments?.dob)
                            ).toDateString()}
                          </div>
                        </div>
                        <div>
                          <h2 className="text-[#1E1B59] md:text-lg text-base">
                            Registered Mobile Number
                          </h2>
                          <div className="text-gray-600 text-opacity-75 md:text-lg px-4 py-1 mt-2 border rounded-lg border-[#1E1B59]/20 text-base w-full">
                            {selectedInstallments?.phone_number &&
                              selectedInstallments?.phone_number[0]}
                          </div>
                        </div>
                        <div>
                          <h2 className="text-[#1E1B59] md:text-lg text-base">
                            Father’s Name
                          </h2>
                          <div className="text-gray-600 text-opacity-75 md:text-lg px-4 py-1 mt-2 border rounded-lg border-[#1E1B59]/20 text-base w-full">
                            {selectedInstallments?.father_name}
                          </div>
                        </div>
                        <p className="text-[#959595BF] text-[10px] font-medium my-4">
                          For changing previous dues amount{" "}
                          <button
                            onClick={() => setShowPreviousDueModalDesktop(true)}
                            className="text-[#6F6AF8E5] text-[12px] font-medium"
                          >
                            Click here
                          </button>
                        </p>
                        <div className="flex justify-between items-center">
                          <h2 className="text-[#1E1B59] md:text-lg text-base flex items-center">
                            Fee to be paid:
                            <span className="text-[#2EB418] text-opacity-90 md:text-2xl text-lg ml-3">
                              ₹{" "}
                              {finalInstallmentCount.length > 1
                                ? (
                                    finalPayAmount +
                                    platform_fee * finalInstallmentCount.length
                                  ).toLocaleString("hi")
                                : (
                                    finalPayAmount + platform_fee
                                  ).toLocaleString("hi")}
                            </span>
                            <sup className="text-black  text-[17px] mt-2 text-opacity-50 font-features sups">
                              *
                            </sup>
                          </h2>

                          <i
                            className={
                              "fa-solid fa-chevron-down transition-all duration-300 transform cursor-pointer " +
                              (open_fee_breakup
                                ? " text-[#1E1B59] -rotate-180"
                                : " text-[#6F6AF8] text-opacity-50  rotate-0")
                            }
                            onClick={() => {
                              set_fee_breakup(!open_fee_breakup);
                              Mixpanel.track("drop-down-button", {
                                drop_down_button: !open_fee_breakup,
                              });
                            }}
                          ></i>
                        </div>
                      </div>
                      {open_fee_breakup && (
                        <div className="p-4 mt-2 max-h-56 bg-white z-10 relative overflow-auto scrollbar-hide border shadow h-full rounded-lg">
                          {selectedInstallments?.selected_installments.map(
                            (feebreakup: any, i: any) => {
                              return (
                                feebreakup.applicable_fee_heads.filter(
                                  (fh) => !fh.paid
                                ).length > 0 && (
                                  <>
                                    <div className="space-y-2" key={i}>
                                      <InstallmentDiscount
                                        feebreakup={feebreakup}
                                        index={i}
                                      />
                                      {feebreakup.applicable_fee_heads.map(
                                        (feehead: any, i: any) => {
                                          return (
                                            <FeeHead
                                              key={i}
                                              feehead={feehead}
                                            />
                                          );
                                        }
                                      )}
                                      <div className="text-[14px] flex justify-between ml-5 text-[#1E1B59]">
                                        <h2 className=" font-medium ">
                                          Fine ( Late Fee )
                                        </h2>
                                        <h4>₹{feebreakup.fine}</h4>
                                      </div>
                                      <div className="text-[14px] flex justify-between ml-5 text-[#1E1B59]">
                                        <h2 className=" font-medium ">
                                          Platform fee
                                        </h2>
                                        <h4>₹{platform_fee}</h4>
                                      </div>
                                      <div className="text-xl flex justify-between ml-5">
                                        <h2 className="text-[16px] font-medium text-[#1E1B59]">
                                          Total
                                        </h2>
                                        <div className="text-[#2EB418]">
                                          ₹
                                          {(
                                            totalAmount[i] +
                                            feebreakup?.fine +
                                            platform_fee
                                          ).toLocaleString("hi")}
                                        </div>
                                      </div>
                                    </div>
                                    {i > 1 &&
                                      i <
                                        selectedInstallments
                                          ?.selected_installments.length -
                                          1 && (
                                        <hr className="border border-dashed my-4" />
                                      )}
                                  </>
                                )
                              );
                            }
                          )}
                          <hr className="my-4" />
                          <IndividualFineCard data={individual_fines} />
                          <div className="text-xl flex justify-between ml-5 mb-2">
                            <h2 className="text-[16px] font-medium text-[#1E1B59]">
                              Previous Session Dues:
                            </h2>
                            <div className="text-[#2EB418]">
                              ₹{others.previous_session_dues}
                            </div>
                          </div>
                          <div className="text-xl flex justify-between ml-5">
                            <h2 className="text-[16px] font-bold text-[#1E1B59]">
                              Grand Total :
                            </h2>
                            <div className="text-[#2EB418]">
                              ₹
                              {finalInstallmentCount.length > 1
                                ? (
                                    finalPayAmount +
                                    platform_fee * finalInstallmentCount.length
                                  ).toLocaleString("hi")
                                : (
                                    finalPayAmount + platform_fee
                                  ).toLocaleString("hi")}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full text-center absolute left-0 p-5 bottom-0 md:block hidden">
                  <div className="flex items-start space-x-2">
                    <input
                      type="checkbox"
                      onChange={(e: any) => set_agree_button(e.target.checked)}
                      name="agree"
                      id="agree"
                      className="cursor-pointer mt-1"
                    />
                    <label htmlFor="agree" className="text-sm text-center">
                      I agree to Edviron's
                      <a
                        href="https://www.edviron.com/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline mx-2"
                      >
                        Terms and Conditions
                      </a>{" "}
                      and
                      <a
                        href="https://www.edviron.com/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline ml-2"
                      >
                        Privacy Policy.
                      </a>
                    </label>
                  </div>

                  <button
                    disabled={!agree_button}
                    onClick={() => {
                      paymentInitiate();
                      Mixpanel.track("pay-now-button", {
                        student: {
                          studentName: selectedInstallments.name,
                          studentClass: selectedInstallments.class,
                          studentId: selectedInstallments._id,
                          schoolName: selectedInstallments?.school?.name,
                          schoolId: selectedInstallments.school_id,
                        },
                        installment: installments_duedate,
                      });
                    }}
                    className="bg-[#6F6AF8] disabled:bg-violet-300 w-full m-auto p-2  text-white   rounded-[15px] h-[50px] mt-2"
                  >
                    Pay Now
                  </button>

                  <h4 className="text-black text-opacity-50 text-[10px] absolute  right-10 font-medium md:bottom-1 -bottom-5">
                    <a
                      href="https://www.edviron.com/terms-and-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      *T&C Apply
                    </a>
                  </h4>
                </div>
              </div>
              <div className="px-4 pt-2 md:hidden block">
                <BackButton
                  label="Review Transaction"
                  className="flex items-center"
                  labelStyle=" text-[18px] text-[#1E1B59]"
                />
                <InstallmentCard
                  installments={selectedInstallments}
                  student={others}
                  transaction_expire_time={transaction_expire_time}
                />
                <p className="text-[#959595BF] text-[10px] font-medium my-4">
                  For changing previous dues amount{" "}
                  <button
                    onClick={() => setShowPreviousDueModal(true)}
                    className="text-[#6F6AF8E5] text-[12px] font-medium"
                  >
                    Click here
                  </button>
                </p>
                <h1 className="text-[20px] text-[#1E1B59] font-bold">
                  Student Details
                </h1>
                <p className="text-[#959595BF] text-[12px] font-medium">
                  A glance of your child details
                </p>
                <StudentDetailsCard student={others} />
              </div>
            </>
          )}
          {showStudentModal && (
            <StudentSelectModal
              open={showStudentModal}
              setOpen={setShowStudentModal}
              className={"max-w-full"}
            >
              <div className="z-20 flex bg-transparent mb-2 justify-center items-center w-full font-extrabold">
                <div
                  className="bg-[#ECEDFBCC] cursor-pointer bg-opacity-20 flex justify-center items-center w-10 h-10 rounded-full"
                  onClick={() => setShowStudentModal(false)}
                >
                  <i className="fa-solid text-[#6F6AF8] text-xl fa-arrow-left"></i>
                </div>
              </div>
              <div className="bg-[#ECEDFB] max-h-[70vh] rounded-t-lg overflow-hidden overflow-y-auto">
                <div className="p-4 max-h-56 bg-white z-50 relative overflow-auto scrollbar-hide  h-full">
                  <h3 className="text-[#1E1B59] font-bold text-xl mb-4">
                    Fee Breakup
                  </h3>
                  {selectedInstallments?.selected_installments.map(
                    (feebreakup: any, i: any) => {
                      return (
                        feebreakup.applicable_fee_heads.filter((fh) => !fh.paid)
                          .length > 0 && (
                          <>
                            <div className="space-y-2" key={i}>
                              <InstallmentDiscount
                                feebreakup={feebreakup}
                                index={i}
                              />
                              {feebreakup.applicable_fee_heads.map(
                                (feehead: any, i: any) => {
                                  return <FeeHead key={i} feehead={feehead} />;
                                }
                              )}
                              <div className="text-[14px] flex justify-between ml-5 text-[#1E1B59]">
                                <h2 className=" font-medium ">
                                  Fine ( Late Fee )
                                </h2>
                                <h4>₹{feebreakup.fine}</h4>
                              </div>
                              <div className="text-[14px] flex justify-between ml-5 text-[#1E1B59]">
                                <h2 className=" font-medium ">Platform fee</h2>
                                <h4>₹{platform_fee}</h4>
                              </div>
                              <div className="text-xl flex justify-between ml-5">
                                <h2 className="text-[16px] font-medium text-[#1E1B59]">
                                  Total
                                </h2>
                                <div className="text-[#2EB418]">
                                  ₹
                                  {(
                                    totalAmount[i] +
                                    feebreakup?.fine +
                                    platform_fee
                                  ).toLocaleString("hi")}
                                </div>
                              </div>
                            </div>
                            {i > 1 &&
                              i <
                                selectedInstallments?.selected_installments
                                  .length -
                                  1 && (
                                <hr className="border border-dashed my-4" />
                              )}
                          </>
                        )
                      );
                    }
                  )}
                  <hr className="my-4" />
                  <IndividualFineCard data={individual_fines} />
                  <div className="text-xl flex justify-between ml-5 mb-2">
                    <h2 className="text-[16px] font-medium text-[#1E1B59]">
                      Previous Session Dues:
                    </h2>
                    <div className="text-[#2EB418]">
                      ₹{others.previous_session_dues}
                    </div>
                  </div>
                  <div className="text-xl flex justify-between ml-5">
                    <h2 className="text-[16px] font-bold text-[#1E1B59]">
                      Grand Total:
                    </h2>
                    <div className="text-[#2EB418]">
                      ₹
                      {finalInstallmentCount.length > 1
                        ? (
                            finalPayAmount +
                            platform_fee * finalInstallmentCount.length
                          ).toLocaleString("hi")
                        : (finalPayAmount + platform_fee).toLocaleString("hi")}
                    </div>
                  </div>
                </div>
                <div className="w-full text-center bg-white z-0 pb-2 ">
                  <div className="bg-[#6F6AF80D] py-4 relative flex justify-center items-center">
                    <h3 className=" text-[16px]">
                      Total{" "}
                      <span className="text-[#2EB418E5]">
                        ₹
                        {finalInstallmentCount.length > 1
                          ? (
                              finalPayAmount +
                              platform_fee * finalInstallmentCount.length
                            ).toLocaleString("hi")
                          : (finalPayAmount + platform_fee).toLocaleString(
                              "hi"
                            )}
                      </span>
                      *
                    </h3>
                  </div>
                  <div className="pt-2 border-t px-4 shadow-box_3">
                    <button
                      onClick={() => {
                        paymentInitiate();
                        Mixpanel.track("pay-now-button", {
                          student: {
                            studentName: selectedInstallments.name,
                            studentClass: selectedInstallments.class,
                            studentId: selectedInstallments._id,
                            schoolName: selectedInstallments?.school?.name,
                            schoolId: selectedInstallments.school_id,
                          },
                          installment: installments_duedate,
                        });
                      }}
                      className="bg-[#6F6AF8] disabled:bg-violet-300 w-full m-auto p-2  text-white   rounded-[15px] h-[60px]"
                    >
                      Pay Now
                    </button>
                    <form
                      method="post"
                      id="ccForm"
                      name="redirect"
                      className="hidden"
                      ref={formRef}
                      action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
                    >
                      <input
                        name="encRequest"
                        type={"hidden"}
                        ref={encRequestRef}
                      ></input>
                      <input
                        name="access_code"
                        type={"hidden"}
                        ref={access_codeRef}
                      ></input>
                    </form>
                  </div>
                </div>
              </div>
            </StudentSelectModal>
          )}
          {showPreviousDueModal && (
            <StudentSelectModal
              open={showPreviousDueModal}
              setOpen={setShowPreviousDueModal}
              className={"max-w-full"}
            >
              <div className="z-20 flex bg-transparent mb-2 justify-center items-center w-full font-extrabold">
                <div
                  className="bg-[#ECEDFBCC] cursor-pointer bg-opacity-20 flex justify-center items-center w-10 h-10 rounded-full"
                  onClick={() => setShowPreviousDueModal(false)}
                >
                  <i className="fa-solid text-[#6F6AF8] text-xl fa-arrow-left"></i>
                </div>
              </div>
              <div className="bg-white h-60 rounded-t-lg overflow-hidden overflow-y-auto">
                <div className="flex flex-col justify-between h-full">
                  <div className="p-4 space-y-6">
                    <h3 className=" text-xl text-[#1E1B59] font-bold">
                      Previous Dues
                    </h3>
                    <Input
                      name="Amount"
                      type="number"
                      value={prev_due_amount}
                      onChange={(e: any, value: any) => {
                        set_prev_due_amount((prev) => {
                          if (prev <= prev_due_amount) {
                            set_prev_due_status(true);
                          } else {
                            set_prev_due_status(false);
                          }
                        });
                        set_prev_due_amount(Number(value));
                      }}
                      min={min_prev_sess_dues_to_pay}
                      max={max_prev_sess_dues_to_pay}
                      add_error={() => {}}
                      onKeyDown={preventNegativeValues}
                      onPaste={preventPasteNegative}
                      className="border rounded-lg border-[#1E1B59]"
                    />
                  </div>
                  {prev_due_status && !error && (
                    <div className="flex justify-center h-20 bg-white items-center shadow-box_3  pt-2 py-3">
                      <h3 className="text-[#2EB418E5] text-base">
                        Your dues have been updated ! Please go back to view
                      </h3>
                    </div>
                  )}
                </div>
              </div>
            </StudentSelectModal>
          )}
          <div className="w-full text-center fixed bottom-0 left-0 bg-white z-0 pb-2 md:hidden block">
            <div className="bg-[#6F6AF80D] h-20 relative flex justify-center items-center">
              <h3 className=" text-xl ">
                Pay{" "}
                <span className="text-[#2EB418E5]">
                  ₹{" "}
                  {finalInstallmentCount.length > 1
                    ? (
                        finalPayAmount +
                        platform_fee * finalInstallmentCount.length
                      ).toLocaleString("hi")
                    : (finalPayAmount + platform_fee).toLocaleString("hi")}
                </span>
                *
              </h3>
              <div className="flex justify-between items-center px-4 absolute bottom-0 left-0 w-full mb-1">
                <h4 className="text-black text-opacity-50 text-[10px] font-medium ">
                  <a
                    href="https://www.edviron.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    *T&C Apply
                  </a>
                </h4>
                <p
                  className="text-right pr-4 text-[#6F6AF8] text-xs font-medium"
                  onClick={() => setShowStudentModal(true)}
                >
                  View Breakup
                </p>
              </div>
            </div>
            <div className="pt-2 border-t px-4 shadow-box_3">
              <button
                onClick={() => {
                  paymentInitiate();
                  Mixpanel.track("pay-now-button", {
                    student: {
                      studentName: selectedInstallments.name,
                      studentClass: selectedInstallments.class,
                      studentId: selectedInstallments._id,
                      schoolName: selectedInstallments?.school?.name,
                      schoolId: selectedInstallments.school_id,
                    },
                    installment: installments_duedate,
                  });
                }}
                className="bg-[#6F6AF8] disabled:bg-violet-300 w-full m-auto p-2  text-white   rounded-[15px] h-[60px]"
              >
                Pay Now
              </button>
              <form
                method="post"
                id="ccForm"
                name="redirect"
                className="hidden"
                ref={formRef}
                action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
              >
                <input
                  name="encRequest"
                  type={"hidden"}
                  ref={encRequestRef}
                ></input>
                <input
                  name="access_code"
                  type={"hidden"}
                  ref={access_codeRef}
                ></input>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymenProceedNew;
