import React, { useRef } from "react";
import "./style.css";
function SequentialInstallmentSelect({
  due_dates,
  paid_till_installment_index,
  pay_till_installment_index,
  set_pay_till_installment_index,
  name,
  classname,
  fee,
  setSelectedStudent,
}: {
  due_dates: Date[];
  paid_till_installment_index: number;
  pay_till_installment_index: number;
  name: string;
  classname: string;
  fee: any;
  setSelectedStudent: any;

  set_pay_till_installment_index: (index: number) => void;
}) {
  // console.log(fee._id);

  return (
    <>
      <div className="checkbox-input text-start my-2 text-sm font-light">
        <div
          className={
            "grid grid-cols-1 h-full transition-all duration-300 z-10 mt-1 overflow-auto  sm:p-4 p-2 text-base  focus:outline-none sm:text-sm " +
            classname
          }
        >
          <label
            key={-1}
            className="text-sm font-normal text-[#1E1B59] truncate relative  cursor-pointer sm:p-4 px-2 py-4 pr-9"
          >
            <input
              className="accent-violet-500"
              checked={pay_till_installment_index === due_dates?.length - 1}
              onChange={(e) => {
                if (!e.target.checked) {
                  setSelectedStudent("");
                  set_pay_till_installment_index(paid_till_installment_index);
                } else {
                  setSelectedStudent(fee._id);
                  due_dates.slice(0, due_dates?.length - 1);
                  set_pay_till_installment_index(due_dates?.length - 1);
                }
              }}
              type="checkbox"
            />
            <span className="ml-6">Select All</span>
          </label>
          {due_dates.map((date: Date, index) => {
            return (
              <label
                key={index}
                className={
                  " flex gap-2 items-center truncate rounded-lg relative cursor-pointer sm:p-4 px-2 py-2 pr-9 "
                }
              >
                <input
                  className="accent-violet-500 disabled:text-gray-300 checkboxes"
                  onChange={(e) => {
                    if (e.target.checked) {
                      set_pay_till_installment_index(index);
                      setSelectedStudent(fee._id);
                    } else {
                      set_pay_till_installment_index(index - 1);
                      setSelectedStudent("");
                    }
                  }}
                  checked={index <= pay_till_installment_index}
                  disabled={
                    index <= paid_till_installment_index ||
                    index > pay_till_installment_index + 1
                  }
                  type="checkbox"
                />
                <div
                  className={
                    "my-auto info-text md:ml-4 ml-2 text-ellipsis grid grid-cols-2 gap-x-4 w-full text-sm font-normal  " +
                    (fee.installments[index].to_pay === 0
                      ? " text-[#1E1B5980] text-opacity-40"
                      : " text-gray-900")
                  }
                >
                  <span>Installment #{index + 1}</span>
                  <span className="pl-10">
                    Deu: {new Date(date).toDateString()}
                  </span>
                </div>
              </label>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default SequentialInstallmentSelect;
