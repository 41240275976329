import React, { useEffect, useState } from "react";

function InstallmentCard({
  installments,
  student,
  transaction_expire_time,
}: any) {
  const [viewAllInstallment, setViewAllInstallment] = useState(false);

  return (
    <div className="rounded-lg my-4 bg-white shadow">
      <div className="h-8 justify-start px-4 items-center rounded-t-lg md:hidden flex w-full bg-[#6F6AF833]">
        <h3 className=" text-[10px] font-bold ">
          You have{" "}
          <span
            className={
              transaction_expire_time <= 59
                ? "  text-[#FC4343E5]"
                : "text-[#6F6AF8] "
            }
          >
            {" "}
            {`${Math.floor(transaction_expire_time / 60)} `}
            {transaction_expire_time -
              Math.floor(transaction_expire_time / 60) * 60 >
              0 &&
              `: ${
                transaction_expire_time -
                  Math.floor(transaction_expire_time / 60) * 60 <
                10
                  ? "0"
                  : ""
              }${
                transaction_expire_time -
                Math.floor(transaction_expire_time / 60) * 60
              } `}
          </span>
          mins left to pay {student?.name}’s fees
        </h3>
      </div>
      <div
        className={
          "p-4  transform transition-all duration-200 space-y-2  " +
          (viewAllInstallment
            ? "  max-h-[8rem] overflow-hidden overflow-y-scroll"
            : " max-h-[4rem] overflow-hidden")
        }
      >
        {installments?.selected_installments
          ?.filter((installment: any) => installment.to_pay !== 0)
          .map((data: any, i: number) => {
            return (
              <div key={i}>
                <div className="flex justify-between items-center">
                  <h3 className="text-[#1E1B59] text-base font-semibold">
                    Installment {i + 1}
                  </h3>
                  <div className="px-4 border border-[#FC434391] text-[10px] border-opacity-50 rounded-full text-[#FC434391] text-opacity-50">
                    Unpaid
                  </div>
                </div>
                <h3 className="text-[#FC4343BF] font-normal text-xs mt-2">
                  Due: {new Date(data?.due_date)?.toDateString()}
                </h3>
              </div>
            );
          })}
      </div>
      <p
        className="text-right pr-4 text-[#6F6AF8] text-[10px] font-medium"
        onClick={() => setViewAllInstallment(!viewAllInstallment)}
      >
        View all
      </p>
      <div className="p-2 space-y-2">
        <hr />
        <p className="text-[#959595BF] font-medium text-[10px]">
          2 days left to pay your child’s fees without any fine
        </p>
        {installments?.selected_installments?.filter(
          (installment: any) => installment.to_pay !== 0
        ).length && (
          <>
            <hr />
            <p className="text-[#2EB418E5] font-medium text-xs">
              You have selected{" "}
              {
                installments?.selected_installments?.filter(
                  (installment: any) => installment.to_pay !== 0
                ).length
              }{" "}
              installment only !
            </p>{" "}
          </>
        )}
      </div>
    </div>
  );
}

export default InstallmentCard;
