import React from "react";
import Card from "../Card/Card";
import ribben from "../../assets/Ribben.svg";
import shield from "../../assets/Shield.svg";
import done from "../../assets/Done.svg";
function HeroHome() {
  return (
    <div className="w-full relative h-full mt-60">
      <div className="font-semibold text-violet-500 text-3xl absolute -top-40  left-40">
        Edviron
      </div>
      <Card
        className={
          "rounded-lg flex justify-center items-center absolute z-30 h-40 -top-7 right-40 w-56"
        }
      >
        <div className="flex h-full text-center mb-4 flex-col items-center">
          <img src={ribben} alt="Ribben" className="h-full w-9" />
          <h3 className="text-xl font-medium text-[#1E1B59]">Trusted Links</h3>
        </div>
      </Card>
      <Card
        className={
          "rounded-lg flex justify-center items-center absolute z-30 h-40 top-28 right-8 w-56"
        }
      >
        <div className="flex h-full text-center flex-col items-center">
          <img src={shield} alt="shield" className="h-full w-9" />
          <h3 className="text-xl font-medium mt-4 text-[#1E1B59]">
            100% Secure Payment
          </h3>
        </div>
      </Card>
      <Card
        className={
          "rounded-lg flex justify-center items-center absolute right-40 top-48 h-40 w-72"
        }
      >
        <div className="flex my-2 flex-col items-center">
          <img src={done} alt="done" className="h-full w-9" />
          <h3 className="text-xl font-medium mt-4 text-[#1E1B59]">
            Payment Successful
          </h3>
        </div>
      </Card>
    </div>
  );
}

export default HeroHome;
