import React from "react";

import { Tooltip as ReactTooltip } from "react-tooltip";
function FeeHead({ set_discounts, id, feehead }: any) {
  return (
    <div className="flex relative flex-col">
      <div className="flex  justify-between ml-5 ">
        <h3
          data-tooltip-id={feehead.fee_head.name}
          className="text-[14px] font-medium cursor-pointer text-[#1E1B59] capitalize"
        >
          {feehead.fee_head.name}
        </h3>

        <h2 className="text-base font-medium text-[#6F6AF8]">
          ₹{feehead.to_pay.toLocaleString("hi")}
        </h2>
      </div>

      <ReactTooltip id={feehead.fee_head.name} place="top">
        <div className="space-y-2">
          <h2>Amount: ₹ {feehead.fee_head.amount.toLocaleString("hi")}</h2>
          <h3>Fee Head Discounts:</h3>
          {feehead?.discounts &&
            feehead?.discounts?.map((discount: any, i: number) => {
              return (
                <p key={i} className="ml-4">
                  Discount {i + 1}: {discount.value}%
                </p>
              );
            })}
        </div>
      </ReactTooltip>
    </div>
  );
}

export default FeeHead;
