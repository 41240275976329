import React from "react";

const Table = ({ data, heading, footer, description, className }: any) => {
  // const []
  return (
    <div className="flex flex-col w-full">
      <div className="table w-full shadow p-5 rounded-lg my-2 text-[#1e1b59]">
        <div className=" flex  w-full">
          <div className="w-full">
            <div className="text-lg font-semibold mx-5 mt-3 mb-2">
              {heading}
            </div>

            <div className="text-sm text-gray-500 mx-5 mb-3">{description}</div>
          </div>
        </div>
        <div
          className={`grid grid-cols-${data[0].length} grid-header p-3 font-semibold m-5 bg-[#ECEDFB] rounded-t-lg text-violet-900`}
        >
          {data[0].map((item: any) => {
            return <div className="text-center">{item}</div>;
          })}
        </div>
        {data.slice(1).map((row: any, key: any) => {
          // console.log({ key });
          return (
            <div
              className={`grid grid-cols-${data[0].length} grid-body items-center p-3 mx-5 my-2 odd:bg-[#ECEDFB] even:bg-white odd:border-none even:border border-[#ECEDFB] rounded-lg`}
            >
              {row.map((item: any) => {
                return <div className="text-center truncate">{item}</div>;
              })}
            </div>
          );
        })}
        {footer}
      </div>
    </div>
  );
};

export default Table;
