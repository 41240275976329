import React, { useState, useEffect } from "react";

import SequentialInstallmentSelect from "../../../components/MultiSelectMenu/SequentialInstallmentSelect";
import MobileMultiSelectMenu from "../../../components/MultiSelectMenu/MobileMultiSelectMenu";
import { Mixpanel } from "../../../utils/mixpanel";
import StudentSelectModal from "../../../components/StudentSelectModal/StudentSelectModal";
import { Link } from "react-router-dom";
function Student({
  fee,
  search_input,
  setselectedInstallments,
  setSelectedStudent,
  selectedStudent,
  selectedInstallments,
  duedate,
}: any) {
  const [show, setShow] = React.useState(false);
  const [showStudentModal, setShowStudentModal] = React.useState(false);
  const [selected_installments, set_selected_installments] = useState<any>([]);
  // const [selectedInstallments, setSelectedInstallments] = useState<any>([]);

  const _paid_till_installment_index: number =
    fee.installments &&
    fee.installments
      ?.map((installment: any, idx: number) =>
        installment?.to_pay === 0 ? idx : -1
      )
      .findIndex((i: number) => i === -1) - 1;

  const [pay_till_installment_index, set_pay_till_installment_index] =
    useState<number>(-3);
  const paid_till_installment_index =
    _paid_till_installment_index === -2
      ? fee.installments.length - 1
      : _paid_till_installment_index;

  if (
    pay_till_installment_index === -3 &&
    paid_till_installment_index !== undefined
  ) {
    set_pay_till_installment_index(paid_till_installment_index);
  }

  useEffect(() => {
    if (fee.installments) {
      setselectedInstallments(
        fee.installments?.slice(0, pay_till_installment_index + 1)
      );
    }
  }, [pay_till_installment_index]);
  // console.log(fee);

  useEffect(() => {
    Mixpanel.track("installment-select-button", {
      student: {
        studentName: fee.name,
        studentClass: fee.class,
        studentId: fee._id,
        schoolName: fee?.school?.name,
        schoolId: fee.school_id,
        installment: duedate,
      },
    });
  }, [selected_installments]);

  const newArr = fee;

  return search_input === "" ||
    fee.name.toLowerCase().includes(search_input.toLowerCase()) ? (
    <>
      <button
        disabled={selectedStudent && fee._id !== selectedStudent}
        onClick={(e: any) => {
          setShow(!show);
          if (window.innerWidth < 764) {
            setShowStudentModal(!showStudentModal);
          } else {
            setShowStudentModal(false);
          }
          Mixpanel.track("student-name-select", {
            student: {
              studentName: fee.name,
              studentClass: fee.class,
              studentId: fee._id,
              schoolName: fee?.school?.name,
              schoolId: fee.school_id,
            },
          });
        }}
        className="first:mt-0 mt-2 w-full rounded-lg transition-all disabled:bg-slate-100 disabled:text-gray-300  duration-300  hover:bg-[#ECEDFB] md:h-16 h-12 md:p-4 p-2 grid grid-cols-3 items-center cursor-pointer "
      >
        <h3 className="md:text-base truncate text-left text-[14px] font-normal  text-[#1E1B59]">
          {fee.name}
        </h3>
        <span className="md:text-base text-[14px] font-normal  text-[#1E1B59]">
          {fee.class} - {fee.section}
        </span>

        <div className="flex items-center w-full justify-end ">
          <h4 className="text-xs font-normal  text-[#6F6AF8] mr-2 sm:block hidden">
            Select Installments
          </h4>

          <i
            className={
              `fa-solid cursor-pointer  text-[#6F6AF8] transition-all fa-chevron-down ` +
              (show ? "-rotate-180 " : " rotate-0")
            }
          ></i>
        </div>
      </button>

      <div className="sm:block hidden">
        {show && (
          <SequentialInstallmentSelect
            name="Installment"
            classname=" rounded-lg shadow ring-1 ring-black ring-opacity-5"
            due_dates={
              fee.installments &&
              fee.installments.map(
                (installment: any) => new Date(installment.due_date)
              )
            }
            fee={fee}
            setSelectedStudent={setSelectedStudent}
            paid_till_installment_index={paid_till_installment_index}
            pay_till_installment_index={pay_till_installment_index}
            set_pay_till_installment_index={set_pay_till_installment_index}
          />
        )}
      </div>

      {showStudentModal && (
        <StudentSelectModal
          open={showStudentModal}
          setOpen={setShowStudentModal}
          className={"max-w-full"}
        >
          <div className="z-20 flex bg-transparent mb-2 justify-center items-center w-full font-extrabold">
            <div
              className="bg-[#ECEDFBCC] cursor-pointer bg-opacity-20 flex justify-center items-center w-10 h-10 rounded-full"
              onClick={() => setShowStudentModal(false)}
            >
              <i className="fa-solid text-[#6F6AF8] text-xl fa-arrow-left"></i>
            </div>
          </div>
          <div className="bg-[#ECEDFB] max-h-[70vh] rounded-t-lg overflow-hidden overflow-y-auto pb-16">
            <div className="p-4 bg-white h-[65px] flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <i className="fa-regular text-[#959595] text-3xl fa-user"></i>
                <h3 className="md:text-base  text-[14px] font-semibold  text-[#1E1B59]">
                  {fee.name} <br /> {fee.school_generated_id}
                </h3>
              </div>
              <div className="flex flex-col space-y-1 max-w-[11rem]">
                <div className="md:text-base  text-[11px] truncate border rounded-full px-4  border-[#6F6AF880] border-opacity-50 font-normal  text-[#1E1B59]">
                  School: {fee?.school?.name}
                </div>
                <div className="md:text-base  text-[11px] max-w-fit border rounded-full px-4  border-[#6F6AF880] border-opacity-50 font-normal  text-[#1E1B59]">
                  Class {fee.class} - {fee.section}
                </div>
              </div>
            </div>
            {!duedate?.length && (
              <h2 className="text-[12px] font-medium text-[#FC4343E5] text-opacity-90 px-2 text-center my-4">
                Oops! It seems you have not selected any installment!{" "}
              </h2>
            )}

            <div>
              <div className="m-4 mt-5 rounded-[20px] bg-white">
                <div>
                  <div className="flex pt-2 px-4 items-center justify-between space-x-3">
                    <h3 className="text-[16px] font-semibold  text-[#1E1B59]">
                      Installment
                    </h3>
                    <div className="px-4 border border-[#FC434391] text-[10px] border-opacity-50 rounded-full text-[#FC434391] text-opacity-50">
                      Required
                    </div>
                  </div>
                  <span className="text-[#1E1B599C] text-[11px] text-opacity-60 font-light ml-4">
                    Select minimum 1 installment to pay
                  </span>
                  <hr className="bg-[#D9D9D9] border mt-2 mb-2" />
                  <div className="max-h-[18rem] overflow-y-auto">
                    <MobileMultiSelectMenu
                      onChange={(e: any, v: any) => {
                        set_selected_installments(v);
                        if (
                          v.filter((x) => x).length >
                          fee?.installments
                            .map(
                              (i) =>
                                i.applicable_fee_heads.filter((fh) => !fh.paid)
                                  .length == 0
                            )
                            .filter((x) => x).length
                        ) {
                          setSelectedStudent(newArr._id);
                        } else {
                          setSelectedStudent("");
                        }

                        setselectedInstallments({
                          selected_installments: fee?.installments
                            .map((f: any, idx: number) => {
                              if (v[idx]) {
                                return f;
                              }
                            })
                            .filter((i) => i),
                          ...newArr,
                        });
                      }}
                      isPaid={fee?.installments.map(
                        (i) =>
                          i.applicable_fee_heads.filter((fh) => !fh.paid)
                            .length == 0
                      )}
                      value={selected_installments}
                      options={fee?.installments}
                      disabled={fee?.installments.map(
                        (i) =>
                          i.applicable_fee_heads.filter((fh) => !fh.paid)
                            .length == 0
                      )}
                      selected={fee?.installments.map(
                        (i) =>
                          i.applicable_fee_heads.filter((fh) => !fh.paid)
                            .length == 0
                      )}
                      add_error={() => {}}
                    />
                  </div>
                  <hr className="bg-[#D9D9D9] border mt-2" />

                  <div className=" p-4">
                    <h3
                      className={
                        "text-[12px] font-medium  text-opacity-90 " +
                        (duedate?.length > 0
                          ? " text-[#2EB418E5]"
                          : " text-[#FC4343E5]")
                      }
                    >
                      {duedate?.length > 0
                        ? `You have selected ${duedate?.length} installment only !`
                        : " You have not selected any installment! "}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full bg-white absolute bottom-0 p-4 shadow-box_3 rounded-t-lg px-4">
              <Link
                to="/proceed-payment"
                state={{
                  selectedInstallments,
                  installments_duedate: duedate,
                }}
                onClick={() =>
                  Mixpanel.track("proceed-button-click", {
                    studentName: fee.name,
                    studentClass: fee.class,
                    studentId: fee._id,
                    schoolName: fee?.school?.name,
                    schoolId: fee.school_id,
                    installment: duedate,
                  })
                }
              >
                <button
                  disabled={
                    selectedInstallments?.selected_installments?.length >
                    selectedInstallments?.installments?.filter(
                      (i) =>
                        i.applicable_fee_heads.filter((fh) => !fh.paid)
                          .length === 0
                    ).length
                      ? false
                      : true
                  }
                  className="bg-[#6F6AF8] w-full disabled:bg-gray-300 m-auto p-2 h-[60px]  text-white   rounded-[15px]"
                >
                  Proceed
                </button>
              </Link>
            </div>
          </div>
        </StudentSelectModal>
      )}

      <hr className="bg-[#D9D9D9] border my-2" />
    </>
  ) : (
    <></>
  );
}

export default Student;
