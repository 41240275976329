import React from "react";
import { Navigate } from "react-router-dom";

const Home = ({ user, set_user }: any) => {
  // if (!user) return <Navigate to="/login" />;
  // const urlSearchParams = new URLSearchParams(window.location.search);
  // const params = Object.fromEntries(urlSearchParams.entries());
  // let token = params.token;
  // if (token) {
  //   localStorage.setItem("token", token as string);
  // }
  // if(!localStorage["token"]) window.location.href = "https://edviron.com";
  if (!user) return <Navigate to="/login" />;
  else return <Navigate to="/fee-details" />;
  // return localStorage["token"]? (<Navigate to="/fee-details" /> ): (<Navigate to="/login" /> );
};

export default Home;
