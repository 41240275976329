import React from "react";
import { useNavigate } from "react-router-dom";

function BackButton({ label, className, labelStyle }: any) {
  const navigate = useNavigate();
  return (
    <div
      className={"mt-2 ml-2 flex items-center py-2 " + className && className}
    >
      <div className="pr-4 cursor-pointer" onClick={() => navigate(-1)}>
        <i className="fa-solid md:text-2xl text-lg fa-chevron-left"></i>
      </div>

      <div
        className={
          " font-semibold " +
          (labelStyle && labelStyle
            ? labelStyle
            : " text-xl font-semibold text-violet-600")
        }
      >
        {label}
      </div>
    </div>
  );
}

export default BackButton;
