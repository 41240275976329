import React, { useState } from "react";
import Input from "../../../components/Input/Input";
import { sum } from "./DueBreakup";
import Student from "./Student";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../../utils/mixpanel";

function StudentSeachBar({ data, loading }: any) {
  const [selectedInstallments, setselectedInstallments] = React.useState<any>(
    []
  );
  const [search_input, set_search] = useState("");

  const [selectedStudent, setSelectedStudent] = useState<any>("");
  const [show_installments, set_show_installments] = useState(true);
  const {
    name: student_name,
    class: student_class,
    school,
    school_id,
    _id,
    selected_installments,
  }: any = selectedInstallments;

  const installments_duedate = selected_installments
    ?.map((data: any) => {
      if (data.to_pay !== 0) {
        return new Date(data.due_date).toDateString();
      }
    })
    .filter((s: any) => s !== undefined);
  const studentInfo = data?.students.filter(
    (stu: any) => stu._id === selectedStudent
  )[0];

  return (
    <>
      <div
        className={
          "w-full sm:flex flex-col  py-[7.5rem] px-4 md:shadow-[0px_9px_20px_0px_#00000024] top-0 h-full absolute left-0  "
        }
      >
        <div className="rounded-lg w-full mt-6 flex justify-between items-center px-4 bg-[#817DFF] bg-opacity-10">
          <Input
            add_error={() => {}}
            onChange={(v, e) => {
              set_search(e);
            }}
            className="bg-transparent outline-none w-full focus:outline-none focus:ring-transparent border-none"
            prefix={<i className="fa-solid fa-magnifying-glass"></i>}
            placeholder="Select student"
          />
          <i
            className={
              "fa-solid fa-chevron-down cursor-pointer transition-all duration-300 transform text-[#6F6AF8] " +
              (show_installments ? "  -rotate-180" : " rotate-0")
            }
            onClick={() => {
              set_show_installments(!show_installments);
              Mixpanel.track("search-drop-down-menu", {
                drop_down_button: !show_installments,
              });
            }}
          ></i>
        </div>
        {show_installments && (
          <>
            <div className="w-full bg-white h-full shadow-box scrollbar-hide overflow-y-auto p-4 mt-4 rounded-lg">
              {loading ? (
                <>
                  {[1, 2, 3, 4, 5].map((skeleton: any) => {
                    return (
                      <div className="mt-2 w-full rounded-lg bg-gray-200 animate-pulse h-16 p-4 flex justify-between items-center">
                        <div className="h-4 bg-gray-400 w-40 rounded-lg"></div>
                        <div className="h-4 bg-gray-400 w-10 rounded-lg"></div>
                        <div className="h-4 bg-gray-400 w-40 rounded-lg"></div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <p className="text-xs text-red-400 text-left">
                    * select only one student at once
                  </p>
                  {data?.students &&
                    data?.students?.map((installment: any, i: any) => {
                      return (
                        <Student
                          key={i}
                          fee={installment}
                          search_input={search_input}
                          setSelectedStudent={setSelectedStudent}
                          selectedStudent={selectedStudent}
                          setselectedInstallments={setselectedInstallments}
                          selectedInstallments={selectedInstallments}
                          duedate={installments_duedate}
                        />
                      );
                    })}
                </>
              )}
            </div>

            <div className="w-full md:flex hidden absolute left-0 justify-center bottom-0">
              <Link
                to="/proceed-payment"
                className="w-full m-5"
                state={{
                  selectedInstallments:
                    {
                      selected_installments: selectedInstallments,
                      ...studentInfo,
                    } || selectedInstallments,
                  installments_duedate:
                    installments_duedate ||
                    selectedInstallments
                      ?.map((data: any) => {
                        if (data.to_pay !== 0) {
                          return new Date(data.due_date).toDateString();
                        }
                      })
                      .filter((s: any) => s !== undefined),
                }}
                onClick={() =>
                  Mixpanel.track("proceed-button-click", {
                    studentName: studentInfo.name,
                    studentClass: studentInfo.class,
                    schoolName: studentInfo?.school?.name,
                    schoolId: studentInfo.school_id,
                    studentId: studentInfo._id,
                    installment: selectedInstallments
                      ?.map((data: any) => {
                        if (data.to_pay !== 0) {
                          return new Date(data.due_date).toDateString();
                        }
                      })
                      .filter((s: any) => s !== undefined),
                  })
                }
              >
                <button
                  disabled={
                    selectedInstallments.length > 0 &&
                    selectedInstallments
                      ?.map((data: any) => {
                        if (data.to_pay !== 0) {
                          return new Date(data.due_date).toDateString();
                        }
                      })
                      .filter((s: any) => s !== undefined).length
                      ? false
                      : true
                  }
                  className="bg-[#6F6AF8]  w-full disabled:bg-gray-300 m-auto p-2 h-[60px]  text-white   rounded-[15px]"
                >
                  Proceed
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default StudentSeachBar;
