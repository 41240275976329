import { gql } from "@apollo/client";

export const GET_INSTALLMENTS = gql`
  query Parent {
    parent {
      phone_number
      students {
        previous_session_dues
        installments {
          due_date
          to_pay
          discounts {
            _id
            name
            value
          }
          applicable_fee_heads {
            paid
            fee_head {
              amount
              _id
              name
            }
            discounts {
              name
              value
            }
            to_pay
          }
          fine
        }
        name
        section
        class
        dob
        father_name
        _id
        phone_number
        school_generated_id
        school {
          name
          convenience_fee
        }
        school_id
        individual_fines {
          _id
          amount
          name
          is_paid
        }
      }
    }
  }
`;

export const GET_FINAL_AMOUNT = gql`
  mutation Generate_payment_information(
    $pay_till_installment: Float!
    $selected_discounts: [ID!]!
    $student_id: ID!
    $prev_due_amount: Float
  ) {
    generate_payment_information(
      pay_till_installment: $pay_till_installment
      selected_discounts: $selected_discounts
      student_id: $student_id
      prev_due_amount: $prev_due_amount
    ) {
      amount
      unpaid_fines {
        amount
        name
      }
      installments_to_pay {
        applicable_fee_heads {
          amount_after_discounts
        }
        to_pay
        fine
      }
      min_prev_sess_dues_to_pay
      max_prev_sess_dues_to_pay
    }
  }
`;

export const PAY = gql`
  mutation Pay(
    $pay_till_installment: Float!
    $selected_discounts: [ID!]!
    $student_id: ID!
    $is_school: Boolean!
    $prev_due_amount: Float!
  ) {
    pay(
      pay_till_installment: $pay_till_installment
      selected_discounts: $selected_discounts
      student_id: $student_id
      is_school: $is_school
      prev_due_amount: $prev_due_amount
    )
  }
`;
