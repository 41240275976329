import React from "react";
import LoadingIcon from "../../assets/loading.svg";

function Button({
  name,
  onTap,
  className,
}: {
  name: string;
  className: string;
  onTap: () => void;
}) {
  const [loading, set_loading] = React.useState(false);
  return (
    <button
      className={` ${className} my-2 group inline-flex items-center justify-center  w-full`}
      type="submit"
      onClick={async () => {
        set_loading(true);
        await onTap();
        set_loading(false);
      }}
    >
      <span>
        {loading ? (
          <img className="h-[20px]" src={LoadingIcon} alt="loading..." />
        ) : (
          name
        )}
      </span>
    </button>
  );
}

export default Button;
