import React from "react";
import { useEffect, useState } from "react";
import LoadingIcon from "../../assets/loading-purple.svg";
const LoadAndRender = ({
  promise,
  children,
  className,
}: {
  promise: any;
  children: any;
  className: string;
}) => {
  useEffect(() => {
    promise().then(() => {
      set_loading(false);
    }); // eslint-disable-next-line
  }, []);

  const [loading, set_loading] = useState(true);

  return (
    <div
      className={
        "load-and-render w-full flex flex-col items-center justify-center" +
        ((className && className) || (!className && "min-h-screen"))
      }
    >
      {loading ? (
        <div className="flex mx-auto my-auto loading">
          <img src={LoadingIcon} alt="" srcSet="" />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default LoadAndRender;
