import React, { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

function DownloadInvoice({ data, name, title }: any) {
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    html2canvas(data, { scale: 1.5 }).then((canvas: any) => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 0;
      heightLeft -= pageHeight;
      const doc = new jsPDF("p", "mm");
      doc.addImage(canvas, "PNG", 0, position, imgWidth, imgHeight, "", "FAST");
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          canvas,
          "PNG",
          0,
          position,
          imgWidth,
          imgHeight,
          "",
          "FAST"
        );
        heightLeft -= pageHeight;
      }
      if (name) {
        doc.save(`${title}_${name.replace(/\s/g, "_").toLowerCase()}.pdf`);
        setLoading(false);
      }
      if (!name) {
        doc.save("generated.pdf");
        setLoading(false);
      }
    });
  };

  return (
    <div className="flex justify-center mt-5 mb-5">
      <button
        onClick={handleDownload}
        className=" text-violet-600 border border-violet-300 p-2 rounded-md"
      >
        {loading ? (
          <span>
            Downloading
            <i className="fa-solid ml-2 animate-spin fa-spinner"></i>
          </span>
        ) : (
          <span className="mr-1">
            {`Download ${title}`}
            <i className="fa-sharp fa-solid fa-download"></i>
          </span>
        )}
      </button>
    </div>
  );
}

export default DownloadInvoice;
