import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import FeeDetails from "./pages/fee-details/FeeDetails";
import Home from "./pages/home/Home";
import LoadAndRender from "./components/load-and-render/LoadAndRender";
import LogInPage from "./pages/LoginPage/LoginPage";
import HeroHome from "./components/Hero/HeroHome";
import StudentDetails from "./pages/student-details/StudentDetails";
import PaymenProceed from "./pages/student-details/components/PaymenProceed";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import PaymentFailure from "./pages/Payment/PaymentFailure";
import mobilebanner from "./assets/mobile.svg";
import ipadbanner from "./assets/i_pad.svg";
import PaymentPending from "./pages/Payment/PaymentPending";
// import logo from './assets/edviron-logo-transparent.svg'
import BackButton from "./components/BackButton/BackButton";
import "react-tooltip/dist/react-tooltip.css";
import PaymenProceedNew from "./pages/student-details/components/PaymentProceed/Page/PaymentProceedNew";
const get_user = async (token) => {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + token);

  var requestOptions: RequestInit = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const { user, school } = await (
    await fetch(
      process.env.REACT_APP_BACKEND_URL + "/school-auth/get_user",
      requestOptions
    )
  ).json();
  return { ...user, school };
};

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
function App() {
  const [user, set_user] = useState<any>(null);
  let query = useQuery();

  let token: any = query.get("token");
  useEffect(() => {
    if (token) localStorage.setItem("token", token);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      set_user(true);
    }
  }, []);

  return (
    <div className="App">
      <div className="w-full flex xl:flex-row flex-col h-full min-h-screen relative">
        <div className="max-w-lg w-full xl:block hidden h-full  border-r-2">
          <div className="fixed bg-[#ECEDFB] max-w-lg w-full h-full border-r-2 min-h-screen">
            <HeroHome />
          </div>
        </div>
        <div className="xl:hidden  w-full md:block hidden relative xl:min-h-[40vh]">
          <BackButton className=" absolute top-[2rem] z-50 left-5" />
          <img
            src={mobilebanner}
            alt="banner"
            className="absolute sm:hidden block h-full w-full object-cover "
          />
        </div>
        <div className="w-full xl:min-h-screen font-poppins min-h-screen md:bg-none bg-[url('./assets/edviron-logo-transparent.svg')] bg-no-repeat bg-center relative">
          <Routes>
            <Route
              path="/fee-details"
              element={<StudentDetails set_user={set_user} />}
            />
            <Route
              path="/"
              element={<Home user={user} set_user={set_user} />}
            />
            <Route
              path="/login"
              element={<LogInPage user={user} set_user={set_user} />}
            />
            <Route path="/payment/pending/:id" element={<PaymentPending />} />
            <Route path="/payment/success/:id" element={<PaymentSuccess />} />
            <Route path="/payment/failure/:id" element={<PaymentFailure />} />
            {/* <Route path="/proceed-payment" element={<PaymenProceed />} /> */}
            <Route path="/proceed-payment" element={<PaymenProceedNew />} />
          </Routes>
          <ToastContainer
            position="top-center"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </div>
    </div>
  );
}

export default App;
