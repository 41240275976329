import React from "react";
import { getSum } from "../Page/PaymentProceedNew";

import { Tooltip as ReactTooltip } from "react-tooltip";
function IndividualFineCard({ data }: any) {
  return (
    <div className="text-xl flex justify-between ml-5 mb-2">
      <h2
        className="text-[16px] font-medium text-[#1E1B59] cursor-pointer"
        data-tooltip-id="fine"
      >
        Individual Fine:
      </h2>
      <div className="text-[#2EB418]">
        ₹
        {data
          .filter((d: any) => d.is_paid === false)
          .map((s: any) => s.amount)
          .reduce(getSum, 0)
          .toLocaleString("hi")}
      </div>
      <ReactTooltip id="fine" place="top">
        <div className="space-y-2 text-[12px]">
          <h2>
            Amount: ₹{" "}
            {data
              .filter((d: any) => d.is_paid === false)
              .map((s: any) => s.amount)
              .reduce(getSum, 0)
              .toLocaleString("hi")}
          </h2>
          <h3>Individual Fine:</h3>
          {data &&
            data.map((fine: any, i: number) => {
              return (
                <p key={i} className="ml-4">
                  Fine {i + 1}: ₹{fine.amount}
                </p>
              );
            })}
        </div>
      </ReactTooltip>
    </div>
  );
}

export default IndividualFineCard;
